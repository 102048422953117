import request from '@/utils/request'

export function getPoliticsList(data) {
    return request({
        url: '/com/activity/list/'+data.pageNum+'/'+data.pageSize,
        method: 'post',
        params: data.form
    })
}

export function selPolitics(data) {
    return request({
        url: '/com/activity/sel',
        method: 'get',
        params: data
    })
}

export function addApply(data) {
    return request({
        url: '/com/apply/add',
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

export function addActivity(data) {
    return request({
        url: '/com/activity/add',
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}