<template>
  <div class="">
    <a-modal v-model="visible"  title="活动报名" ok-text="提交" cancel-text="取消" @ok="hideModal" @cancel="noModal()" >
      <div class="applicationForm">
        <a-form-model  ref="form" :model="form"  label-width="auto"  :label-col="{ span:5 }"  :wrapper-col="{ span:19}">
          <a-form-model-item label="报名活动名称">
            <span style="font-size: 16px;color:#333333 " v-text="name"></span>
          </a-form-model-item>
          <a-form-model-item label="活动开始时间" >
            <span style="font-size: 16px;color:#333333 " v-text="startTime"></span>
          </a-form-model-item>
          <a-form-model-item label="活动结束时间" >
            <span style="font-size: 16px;color:#333333 " v-text="endTime"></span>
          </a-form-model-item>
          <a-form-model-item label="报名人数">
            <a-row>
              <a-col :span="8">
                <a-input class="left-input " oninput="if(value<1||value>20)value=1" blur="if(form.number<1||value>20)form.number=1"  type="number" v-model="form.number"></a-input>
              </a-col>
              <a-col :span="16">
                <a-button @click="numberA()" class="" style="height: 40px;" type="primary" >确定</a-button>
              </a-col>
            </a-row>
          </a-form-model-item>
          <a-row style="margin-top: 20px">
            <a-col :span="6" class="col-teble">
              <span style="color: red">*&nbsp;</span>单位
            </a-col>
            <a-col :span="5" class="col-teble">
              <span style="color: red">*&nbsp;</span>姓名
            </a-col>
            <a-col :span="5" class="col-teble">
              <span style="color: red">*&nbsp;</span>职务
            </a-col>
            <a-col :span="6" class="col-teble">
              <span style="color: red">*&nbsp;</span>联系电话
            </a-col>
            <a-col :span="2" class="col-teble col-teble-last  col-teble-last1">
              <span>操作</span>
            </a-col>
          </a-row>
          <div v-for="(intm,index) in form.list" :key="index">
            <a-row>
              <a-col :span="6">
                <a-form-model-item :label-col="{ span:0 }"  :wrapper-col="{ span: 24}"
                                   :prop="'list.' + index + '.unitName'"
                                   :rules="{
                                    required: true,
                                    message: '请输入单位名称',
                                    trigger: 'blur',
                                  }"
                >
                  <a-input  max-length="100" v-model="intm.unitName"></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="5">
                <a-form-model-item :label-col="{ span:0 }" :wrapper-col="{ span: 24}"
                                   :prop="'list.' + index + '.name'"
                                   :rules="{
                                    required: true,
                                    message: '请输入联系人姓名',
                                    trigger: 'blur',
                                  }"
                              >
                  <a-input  max-length="32" v-model="intm.name"></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="5">
                <a-form-model-item :label-col="{ span:0 }"  :wrapper-col="{ span: 24}"
                                   :prop="'list.' + index + '.position'"
                                   :rules="{
                                    required: true,
                                    message: '请输入联系人职务',
                                    trigger: 'blur',
                                  }"
                >
                  <a-input  max-length="255" v-model="intm.position"></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item :label-col="{ span:0 }"  :wrapper-col="{ span: 24}"
                                   :prop="'list.' + index + '.phone'"
                                   :rules="[{
                                    required: true,
                                    message: '请输入联系人电话',
                                    trigger: 'blur',
                                  },{ pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/, message: '请输入正确的手机号！' }]"
                >
                  <a-input max-length="11"  v-model="intm.phone"></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="2"  class="col-teble col-teble-last">
                <span @click="delect(intm)">删除</span>
              </a-col>
            </a-row>
          </div>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>
<script>
import {addApply} from '../../../api/company/activity'
export default {
  name: 'addApply',
  components: {},
  props: {
    name:String,
    startTime:String,
    endTime:String,
    activityId:Number,
    visible:Boolean
  },
  watch:{
  },
  data() {
    return {
      form: {
        id:null,
        activeId:null,
        number:1,
        activeName:null,
        list:[],
      },
    }
  },
  methods: {
    delect(intm){
      intm.unitName=null;
      intm.name=null;
      intm.phone=null;
      intm.position=null;

    },
   async numberA(){
      let index=this.form.number-this.form.list.length;
      let indexB=this.form.list.length-this.form.number;
      for (let i = 0; i <index ; i++) {
       await this.form.list.push({
            id:null,
            activeId:this.activityId,
            number:this.form.number,
            activeName:this.name,
            unitName:null,
            name:null,
            phone:null,
            position:null
        })
      }
      for (let i = 0; i <indexB; i++) {
        this.form.list.pop()
      }
    },
    hideModal(e) {
      //console.log(this.demandId,e)
      this.$refs.form.validate( async valid =>{
        if (valid) {
          this.form.activeId=this.activityId;
          //console.log(this.form.activityId,"this.form.activityId")
         // this.form.activeName=this.name
          addApply(this.form.list).then(()=>{
            this.$message.success("报名成功")
            this.visible = false;
            this.$parent.visible=false;
            // this.$parent.showFormB();
            //  this.$emit('showFormB');
          })
        }
      })
    },
    noModal(){
      //  this.$emit('showFormB');
      // this.$parent.showFormB();
      this.$parent.visible=false;
    }
  },
  mounted() {
    //console.log("ada")
    this.numberA();
  }
}
</script>
<style lang="scss" scoped>
.ant-modal-root {
  .applicationForm {
    background: #FFFFFF;
    .ant-input,.col-teble{
      text-align: center;
      line-height: 42px;
      height: 40px;
      background: #FFFFFF;
      border: 1px solid #E1E1E1;
      border-right: 0px solid #E1E1E1;
      border-radius:0 0 0 0;
    }
    .col-teble{
      background-color: rgb(207,221,240);
      border-bottom: 0px solid #E1E1E1;
    }
    .col-teble-last{
      background-color:#FFFFFF;
      border-bottom: 1px solid #E1E1E1;
      border-right: 1px solid #E1E1E1;
    }
    .col-teble-last1{
      background-color: rgb(207,221,240);
      border-bottom: 0px solid #E1E1E1;
    }
    .left-input{
      border-right: 1px solid #E1E1E1;
      width: 130px;
    }
/*    .ant-input:last-child{
      border-right: 1px solid #E1E1E1;
    }*/
    .ant-form-item {
      margin-bottom: 0px;
    }
  }
}
</style>
<style lang="scss">
.ant-modal-root{
  .ant-modal-content {
    width: 650px;
    /*height: 475px;*/
    margin-top: 200px;
    padding-bottom: 20px;
    background: #FFFFFF;

    .ant-modal-header {
      width: 650px;
      height: 59px;
      background: #075FEA;
      text-align: center;

      .ant-modal-title {
        font-size: 22px;
        font-family: Hiragino Sans GB;
        color: #FFFFFF;
      }
    }

    .ant-modal-close {
      color: #e0ebfd;
    }
  }

  .ant-modal-body {
    padding-bottom: 0px;
  }

  .ant-modal-footer {
    border-top-width: 0px;

    .ant-btn {
      font-size: 18px;
      width: 119px;
      height: 42px;
      color: #075FEA;
    }

    .ant-btn-primary {
      margin-left: 20px;
      margin-right: 186px;
      background: #075FEA;
      color: #FFFFFF;
    }
  }

  #rcDialogTitle37 {
    text-align: center !important;
  }
}

</style>